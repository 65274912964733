<template>
  <b-container fluid v-if="($root.$data.portalInfo.useBalanceEntries) && $root.$data.me.access.ST1">
    <b-row>
      <b-col cols="12">
        <page-snippet name="royalties" />
      </b-col>
      <b-col cols="4">

        <b-card no-body>
          <template v-slot:header>
            {{ $t("Filters / Options") }}
          </template>

          <b-card-body v-bind:style="{ minHeight: beHeight + 'px' }">
            <b-container fluid class="p-0">
              <b-row class="mb-3" v-if="$root.$data.me.IsSuperUser || $root.$data.me.PortalAdmin">
                <b-col>
                  {{ $t('Beneficiaries') }}
                  <ApiLookup v-model="filter.selectedBeneficiaries" source="analytics/find-beneficiaries" :filter="filter"  />
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" class="mb-3">
                  <div class="mr-2">{{ $t('Period') }}:</div>

                  <span class="mt-2 text-nowrap">
                    <b-form-select v-model="filter.apFrom" :options="apOptions" size="sm"></b-form-select>
                    - 
                    <b-form-select v-model="filter.apTo" :options="apOptions" size="sm"></b-form-select>
                  </span>
                </b-col>

                <b-col cols="12" class="mb-3" v-if="false">
                  <div class="mr-2">Export type:</div>
                  <b-button-group class="mr-4">
                    <b-button
                        v-on:click="exportType = 'xlsx'"
                        v-bind:class="{ active: exportType == 'xlsx' }">
                      Excel
                    </b-button>
                    <b-button
                        v-on:click="exportType = 'csv'"
                        v-bind:class="{ active: exportType == 'csv' }">
                      CSV
                    </b-button>
                    <b-button
                        v-on:click="exportType = 'tab'"
                        v-bind:class="{ active: exportType == 'tab' }">
                      TAB
                    </b-button>
                  </b-button-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" class="mb-3">
                  <div class="mr-2">{{ $t('Results') }}:</div>

                  <b-button-group class="mr-2">
                    <b-button
                        v-on:click="perPage = 5"
                        v-bind:class="{ active: perPage == 5 }">
                      5
                    </b-button>
                    <b-button
                        v-on:click="perPage = 10"
                        v-bind:class="{ active: perPage == 10 }">
                      10
                    </b-button>
                    <b-button
                        v-on:click="perPage = 25"
                        v-bind:class="{ active: perPage == 25 }">
                      25
                    </b-button>
                  </b-button-group>
                </b-col>

                <b-col cols="12" class="mb-3">
                  <div>{{ $t('Number format') }}:</div>
                  <b-button-group class="mr-2">
                    <b-button
                        v-on:click="numberLocale = 'intl'"
                        v-bind:class="{ active: numberLocale == 'intl' }">
                      1 000.00
                    </b-button>
                    <b-button
                        v-on:click="numberLocale = 'en'"
                        v-bind:class="{ active: numberLocale == 'en' }">
                      1,000.00
                    </b-button>
                    <b-button
                        v-on:click="numberLocale = 'nl'"
                        v-bind:class="{ active: numberLocale == 'nl' }">
                      1.000,00
                    </b-button>
                  </b-button-group>
                </b-col>
              </b-row>
            </b-container>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col>
        <b-card
            no-body>
          <template v-slot:header>
            {{ $t('Statements') }}
          </template>

          <b-card-body v-bind:style="{ height: beHeight + 'px' }">
            <p v-if="bfs.selected == null">
              {{ $t('Click on a beneficiary in the table below to view the entries') }}.
            </p>

            <div v-if="bfs.selected != null" class="mb-1">
              <b-button size="sm" variant="primary" class="mr-2"
                        v-on:click="entriesStateAll">
                {{ $t('Select all') }}
              </b-button>

              <b-button size="sm" variant="primary" class="mr-2"
                        v-on:click="entriesStateAll(false)">
                {{ $t('Unselect all') }}
              </b-button>

              <span class="mr-2" id="exportWrapper" >
                <b-button size="sm" variant="primary"
                    v-on:click="entriesExportSelected"
                    :disabled="entriesSelected.length == 0 || entries.exportBusy">
                  {{ $t('Export selected') }}
                  <span v-show="entriesSelected.length > 0">({{ entriesSelected.length }})</span>
                </b-button>
              </span>
              <b-popover
                :disabled="true || $root.$data.me.isImpersonatingOrSuperuser"
                :target="'exportWrapper'"
                triggers="hover"
                :content="'This option will be available soon'"
              ></b-popover>
              <b-spinner small v-show="entries.exportBusy" class="mr-2" label="Spinning"></b-spinner>

              <b-button size="sm" variant="primary" class="mr-2"
                  v-if="$root.$data.me.access.ROY" 
                  v-on:click="entriesViewSelected"
                  :disabled="entriesSelected.length == 0">
                {{ $t('View selected') }}
                <span v-show="entriesSelected.length > 0">({{ entriesSelected.length }})</span>
              </b-button>
            </div>

            <b-table
                v-bind:style="{ height: (beHeight-75) + 'px' }"
                sticky-header
                v-if="bfs.selected != null"
                class="smaller-table"
                style="overflow-y: auto"
                small
                striped
                hover
                :items="bfs.selected.balanceEntries"
                :fields="entries.fields"
                @row-clicked="onEntriesRowClicked">

              <template v-slot:cell(selected)="data">
                <span v-if="data.item.type == 2 && data.item.statementReference != null" class="font-weight-bold text-primary">
                  <template v-if="data.item.selected">
                    <span aria-hidden="true">&check;</span>
                    <span class="sr-only">Selected</span>
                  </template>
                  <template v-else>
                    <span aria-hidden="true">-</span>
                    <span class="sr-only">Not selected</span>
                  </template>
                </span>
              </template>

              <template v-slot:head(amount)="data">
                <div class="text-nowrap text-right">{{ $t(data.label) }}</div>
              </template>

              <template v-slot:head(dateTime)="">
                <div class="text-nowrap">{{ $t('Date') }}</div>
              </template>

              <template v-slot:cell(accountingPeriod)="data">
                <div class="text-nowrap text-right">{{ data.item.accountingPeriod | formatAccountingPeriod }}</div>
              </template>

              <template v-slot:cell(amount)="data">
                <div class="text-nowrap text-right" style="font-family: 'Roboto Mono', monospace;">{{ data.item.amount | numberFormat(numberDecimals, numberLocale) }}</div>
              </template>

              <template v-slot:cell(dateTime)="data">
                <div class="text-nowrap">{{ data.item.dateTime | dateFormat }}</div>
              </template>
            </b-table>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col cols="3">
        <b-card
            no-body>
          <template v-slot:header>
            {{ $t('Summary') }}
          </template>

          <b-card-body v-bind:style="{ height: beHeight + 'px' }">

            <div v-if="summary.length == 0">No data available</div>

            <b-table
                class="smaller-table text-nowrap"
                thead-class="d-none"
                small
                striped
                hover
                responsive
                :items="summary"
                :fields="summaryFields"
                >
              <template v-slot:cell(value)="data">
                <div class="text-nowrap text-right" style="font-family: 'Roboto Mono', monospace;" v-if="data.item.value != null">
                  {{ data.item.value | numberFormat(0, numberLocale) }}
                </div>
              </template>
              
              <template v-slot:cell(percentage)="data">
                <div class="text-nowrap text-right" style="font-family: 'Roboto Mono', monospace;" v-if="data.item.percentage != null" 
                  v-bind:class="{ 'font-weight-bold': true, 'text-success': data.item.percentage > 0, 'text-danger': data.item.percentage < 0 }">
                  <span v-if="data.item.percentage > 0">+</span>{{ data.item.percentage | numberFormat(0, numberLocale) }} %
                </div>
              </template>

            </b-table>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card>
          <div slot="header">
            {{ $t('Beneficiaries') }} - 
            {{ ((bfs.currentPage - 1) * perPage) + 1 }} - {{ ((bfs.currentPage) * perPage) }} of {{ bfs.totalRows }}

            <b-spinner small v-show="bfs.loading" label="Spinning"></b-spinner>
          </div> 

          <b-pagination
            v-model="bfs.currentPage"
            :total-rows="bfs.totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"></b-pagination>

          <b-table
            small
            striped
            id="bfs-table"
            class="smaller-table"
            responsive
            selectable
            select-mode="single"
            @row-selected="onBfSelected"
            :items="bfProvider"
            :fields="bfs.fields"
            :current-page="bfs.currentPage"
            :per-page="perPage"
            :filter="filter">

            <template v-slot:head()="scope">
              <div class="text-nowrap">
                {{ scope.label }}
              </div>
            </template>

            <template v-slot:cell()="scope">
              <div class="text-nowrap">
                {{ scope.value }}
              </div>
            </template>

            <template v-slot:head(statementReference)="">
              <div class="text-nowrap">{{ $t('Reference') }}</div>
            </template>

            <template v-slot:head(beneficiaryCode)="">
              <div class="text-nowrap">{{ $t('Beneficiary Code') }}</div>
            </template>

            <template v-slot:head(accountingPeriod)="data">
              <div class="text-nowrap">{{ $t(data.label) }}</div>
            </template>

            <template v-slot:head(currency)="data">
              <div class="text-nowrap">{{ $t(data.label) }}</div>
            </template>

            <template v-slot:head(openingBalance)="data">
              <div class="text-nowrap text-right">{{ $t(data.label) }}</div>
            </template>

            <template v-slot:cell(accountingPeriod)="data">
              <div class="text-nowrap">{{ data.item.accountingPeriod | formatAccountingPeriod }}</div>
            </template>

            <template v-slot:cell(openingBalance)="data">
              <div class="text-nowrap text-right" style="font-family: 'Roboto Mono', monospace;">{{ data.item.openingBalance | numberFormat(numberDecimals, numberLocale) }}</div>
            </template>

            <template v-slot:head(paymentsAmount)="data">
              <div class="text-nowrap text-right">{{ $t(data.label) }}</div>
            </template>

            <template v-slot:cell(paymentsAmount)="data">
              <div class="text-nowrap text-right" style="font-family: 'Roboto Mono', monospace;">{{ data.item.paymentsAmount | numberFormat(numberDecimals, numberLocale) }}</div>
            </template>

            <template v-slot:head(advancesAmount)="data">
              <div class="text-nowrap text-right">{{ $t(data.label) }}</div>
            </template>

            <template v-slot:cell(advancesAmount)="data">
              <div class="text-nowrap text-right" style="font-family: 'Roboto Mono', monospace;">{{ data.item.advancesAmount | numberFormat(numberDecimals, numberLocale) }}</div>
            </template>

            <template v-slot:head(royaltyAmount)="data">
              <div class="text-nowrap text-right">{{ $t(data.label) }}</div>
            </template>

            <template v-slot:cell(royaltyAmount)="data">
              <div class="text-nowrap text-right" style="font-family: 'Roboto Mono', monospace;">{{ data.item.royaltyAmount | numberFormat(numberDecimals, numberLocale) }}</div>
            </template>

            <template v-slot:head(adjustmentsAmount)="data">
              <div class="text-nowrap text-right">{{ $t(data.label) }}</div>
            </template>

            <template v-slot:cell(adjustmentsAmount)="data">
              <div class="text-nowrap text-right" style="font-family: 'Roboto Mono', monospace;">{{ data.item.adjustmentsAmount | numberFormat(numberDecimals, numberLocale) }}</div>
            </template>

            <template v-slot:head(closingBalance)="data">
              <div class="text-nowrap text-right">{{ $t(data.label) }}</div>
            </template>

            <template v-slot:cell(closingBalance)="data">
              <div class="text-nowrap text-right" style="font-family: 'Roboto Mono', monospace;">{{ data.item.closingBalance | numberFormat(numberDecimals, numberLocale) }}</div>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'RoyaltyAccounting',
    components: {
        ApiLookup: () => import('@/components/ApiLookup.vue'),
        'page-snippet': () => import('@/components/PageSnippet.vue')
    },
    data () {
      return {
        filter: {
          apFrom: null,
          apTo: null,
          selectedBeneficiaries: [],
          includeBalances: true,
        },
        beHeight: 390,
        perPage: 10,
        exportType: 'xlsx',
        apYears: [],
        apMonths: [],
        apOptions: [],
        numberLocale: 'nl',
        numberDecimals: 2,
        bfs: {
          totalRows: 0,
          fields: [
            'beneficiaryCode',
            'accountingPeriod',
            'currency',
            'openingBalance',
            'paymentsAmount',
            'advancesAmount',
            'royaltyAmount',
            'adjustmentsAmount',
            'closingBalance',
            /*'actions', */
          ],
          rows: [],
          currentPage: 1,
          loading: false,
          selected: null
        },
        entries: {
          fields: [
            {
              key: 'selected',
              label: '',
            },
            'description',
            'amount',
            'dateTime'
          ],
          exportBusy: false,
        },
        summary: [],
        summaryFields: [
          'title',
          'value',
          'percentage'
        ],
      }
    },
    methods: {
      onBfSelected (ref) {
        if (ref.length > 0)
          this.bfs.selected = ref[0]
        else
          this.bfs.selected = null
      },
      onEntriesRowClicked (item, index) {
        if (item.statementReference != null) {
          this.bfs.selected.balanceEntries[index].selected = !item.selected
          this.$forceUpdate()
        }
      },
      entriesStateAll(state = true) {
        let src = this.bfs.selected.balanceEntries
        src.forEach((item, index) => {
          if (item.type == 2 && item.statementReference != null)
            src[index].selected = state
        })
      },
      entriesExportSelected () {
        let filter = {
          apFrom: this.filter.apFrom,
          apTo: this.filter.apTo,
          selectedRefs: []
        }

        // Run through selected balance entries
        let allSelected = true
        let unselectedCount = 0

        this.bfs.selected.balanceEntries.forEach(item => {
          console.log(item)
          if (item.type != 2) return

          if (item.selected) {
            filter.selectedRefs.push({
              key: item.statementReference
            })
          } else {
            unselectedCount++;
            allSelected = false
          }
        })

        if (filter.selectedRefs.length == 0) {
          this.$bvModal.msgBoxOk('Nothing was selected to export')
          return
        }

        // Construct filename
        let statementPrefix = 'TheSource_'
        if (this.$root.$data.me.ProviderID == 'AC07FEEB-3D38-714D-8AB9-C3EDB6299403') 
        {
          statementPrefix = 'Charly_'
        }

        let filename =  statementPrefix + 
                        'RoyaltyStatement_' + 
                        this.bfs.selected.accountingPeriod + '_' +
                        this.bfs.selected.beneficiaryCode
        
        if (!allSelected) {
          if (filter.selectedRefs.length == 1) {
            filename = filename + '_' + filter.selectedRefs[0].key
          } else {
            filename = filename + '_Some_Statements'
          }
        }

        // Assemble parameters
        let params = {
          exportFileType: this.exportType,
          exportColumns: 'all',
          filter: filter,
          filename: filename,
          numberLocale: this.numberLocale,
        }

        // Start export
        this.entries.exportBusy = true
        this.$http
          .post('analytics/royalty-transactions', params)
          .then((result) => {
            let jobId = result.data.jobId
            setTimeout(() => {
              this.$http.get('users/me/jobs/' + jobId)
                .then(response => {
                  if (response.data.status == 'done') {
                    let link = document.createElement('a')
                    link.href = '/api/users/me/export-files/' + jobId
                    document.body.appendChild(link)
                    link.click()
                  } else {
                    this.$bvModal.msgBoxOk('Export started, check the "Export" menu-item to download.')
                  }
                  this.entries.exportBusy = false
                })
            }, 2000)
          });
      },
      entriesViewSelected () {
        this.entriesView(this.entriesSelected)
      },
      entriesView(src) {
        let refs = []
        src.forEach(item => {
          refs.push(item.statementReference)
        })
        let routeData = this.$router.resolve({
          name: 'royalty-analytics', 
          query: {
            view: 'table',
            statementRefs: refs.join(','),
            accountingPeriod: this.bfs.selected.accountingPeriod,
          }
        });
        window.open(routeData.href, '_blank');
      },
      bfProvider (ctx) {
        if (this.filter.apFrom == null) return []

        return new Promise((resolve) => {
          if (this.cancelToken) {
            this.cancelToken.cancel()
          }
          this.cancelToken = axios.CancelToken.source()

          this.bfs.loading = true
          this.$http
              .get('beneficiaries', { 
                cancelToken: this.cancelToken.token,
                params: ctx,
              })
              .then(response => {
                  let data = response.data.map(item => {
                    const balances = []
                    for (const balance of item.Balances) {
                      balances[balance.Description] = balance.Amount
                    }

                    const out = {
                      beneficiaryCode: item.BeneficiaryCode,
                      balanceEntries: item.BalanceEntries,
                      accountingPeriod: item.PeriodID,
                      currency: item.CurrencyID,
                      openingBalance: balances['Balance Brought Forward'] || balances['Saldo vorige periode'],
                      paymentsAmount: balances['Payment'] || balances['Betalingen'],
                      advancesAmount: balances['Advance']  || balances['Voorschotten'],
                      royaltyAmount: balances['Royalties'],
                      adjustmentsAmount: balances['Adjustment'] || balances['Correcties'],
                      closingBalance: balances['Current Balance'] || balances['Huidig saldo'],
                    }
              
                    return out
                  })

                  this.bfs.totalRows = Number(response.headers['x-pagination-count'])
                  this.bfs.loading = false

                  data.forEach((item, indexRef) => {
                    item.balanceEntries.forEach((item, indexEntry) => {
                      data[indexRef].balanceEntries[indexEntry].selected = false
                    })
                  })
                  
                  resolve(data)
              }).catch(function () {
                  resolve([])
              })
        })
      },
      downloadStatement (item, doctype, layout) {
        this.$http
            .get('getReportRunLink', {
              params: {
                DefinitionID: '7bfbc1ea-23cd-4fd8-967c-d209044f4504',
                InstanceID: item.statementId,
                DocumentType: doctype,
                Layout: layout,
              }
            })
            .then(response => {
                var data = response.data.ReportRun

                const link = document.createElement('a')
                link.href = data.URL
                document.body.appendChild(link)
                link.click()
            })
      }
    },
    computed: {
      entriesSelected () {
        let selected = []
        this.bfs.selected.balanceEntries.forEach(item => {
          if (item.selected) {
            selected.push(item)
          }
        })

        return selected
      }
    },
    async mounted () {
      this.$http
        .get('accounting/beneficiary-summary')
        .then(response => {
          this.summary = response.data
        })

      let response = await this.$http.get('analytics/accounting-periods')
      let result = response.data
      this.apOptions = []
      result.forEach(item => {
        this.apOptions.push({
          value: item.id,
          text: item.description
        })
      })
      this.filter.apFrom = this.apOptions[this.apOptions.length-1].value
      this.filter.apTo = this.filter.apFrom

      /*
      response = await this.$http.post('accounting/statement-refs', { 
        filter: this.filter,
        perPage: 50,
      } )

      let data = response.data

      data.rows.forEach((item, indexRef) => {
        item.balanceEntries.forEach((item, indexEntry) => {
          data.rows[indexRef].balanceEntries[indexEntry].selected = false
        })
      })

      const entries = []
      
      for (let row of data.rows) {
        for (let entry of row.balanceEntries) {
          if (entry.type != 2) continue

          entries.push(entry)
        }
      }

      this.bfs.selected = {
        balanceEntries: entries,
      }
      */
    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>


<style scoped>
  .btn { white-space: nowrap; }
</style>

<style>
  .custom-select {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  .smaller-table {
    font-size: 0.8rem;
  }

  .table.b-table > tbody  .table-active {
    background-color: lightblue;
  }

  .table.b-table > tbody *:focus {
    outline: none;
  }

  .b-table-sticky-header > .table.b-table > thead > tr > th {
    top: -1px;
  }
</style>
